<template>
  <div class="otp-container">
    <el-input ref="otpcode1"
              type="password"
              :value="formData.otpcode1"
              :disabled="isTemporarilyDisabled"
              inputmode="numeric"
              pattern="[0-9]*"
              autocomplete="one-time-code"
              @input="handleInput(1, $event)"
              @keyup.delete.native="handleDelete(1)" />
    <el-input ref="otpcode2"
              type="password"
              :value="formData.otpcode2"
              :disabled="isTemporarilyDisabled"
              @input="handleInput(2, $event)"
              @keyup.delete.native="handleDelete(2)" />
    <el-input ref="otpcode3"
              type="password"
              :value="formData.otpcode3"
              :disabled="isTemporarilyDisabled"
              @input="handleInput(3, $event)"
              @keyup.delete.native="handleDelete(3)" />
    <el-input ref="otpcode4"
              type="password"
              :value="formData.otpcode4"
              :disabled="isTemporarilyDisabled"
              @input="handleInput(4, $event)"
              @keyup.delete.native="handleDelete(4)" />
  </div>
</template>
<script>
export default {
  props: {
    otpNumber: {
      type: String,
      default: ''
    },
    focusFirstInput: {
      type: Boolean,
      default: false
    },
    resetFields: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      isTemporarilyDisabled: false,
      formData: {
        otpcode1: null,
        otpcode2: null,
        otpcode3: null,
        otpcode4: null,
      }
    }
  },
  computed: {
    otpcode() {
      let form = this.formData;
      if(Object.keys(this.formData).some(key => this.formData[key] == null)) return '';
      return `${form.otpcode1}` +
        `${form.otpcode2}` +
        `${form.otpcode3}` +
        `${form.otpcode4}` 
    },
  },
  mounted() {
    this.setFocusFirstInput();

    const target = document.querySelector('div.otp-container');
    target.addEventListener('paste', (event) => {
      let paste = (event.clipboardData || window.clipboardData).getData('text');
      if (paste != null) {
        let pastedOtp = paste.match(/(\d+)/);
        if (pastedOtp != null && pastedOtp[0] != null) {
          let otpPastedNumber = pastedOtp[0];
          if (otpPastedNumber.length === 4) {
            for (let index in otpPastedNumber) {
              let otpCodeRef = `otpcode${parseInt(index) + 1}`;
              this.formData[otpCodeRef] = otpPastedNumber[index];
            }
            if (this.otpcode.length === 4) {
              this.$emit('done', this.otpcode);
            } 
          }
        }
      }
      event.preventDefault();
    });
  },
  methods: {
    resetInputFields() {
      this.formData.otpcode1 = null;
      this.formData.otpcode2 = null;
      this.formData.otpcode3 = null;
      this.formData.otpcode4 = null;
    },
    setFocusFirstInput() {
      this.$refs.otpcode1.focus();
    },
    isFilled() {
      return this.otpcode.length === 4;
    },
    handleInput(no, event) {
      // let event = e.data;
      let inputEvent = /[0-9]/g.test(event) ? (event + '') : null;
      if (inputEvent == null) return;

      let parsedValue = parseInt(inputEvent);
      let isLast = no === 4;
      let otpCodeRef = `otpcode${no}`;

      if (parsedValue && this.otpcode.length === 4) return;

      if (this.formData[otpCodeRef] != null) {
        let lastNumber = parseInt((parsedValue + '').slice(-1));
        this.handleInput(no + 1, lastNumber);
        return;
      }

      if (isNaN(parsedValue) || parsedValue == null) {
        this.formData[otpCodeRef] = null;
        return;
      }
      this.formData[otpCodeRef] = parsedValue;
      if (!isLast) this.$refs['otpcode' + (no + 1)].focus();
      if (isLast && this.otpcode.length < 4) {
        let missing = Object.keys(this.formData).find(key => this.formData[key] == null);
        this.$refs[missing].focus();
      }
      if (this.otpcode.length === 4) {
        this.$emit('done', this.otpcode);
      }

      this.$emit('change', this.otpcode);
    },
    handleDelete(no) {
      let isFirst = no === 1;
      let otpCodeRef = `otpcode${no}`;
      this.$emit('changeOTP');
      if (this.formData[otpCodeRef] == null && !isFirst) {
        this.$refs[`otpcode${no - 1}`].focus();
        this.formData[`otpcode${no - 1}`] = null;
        return;
      }
      if (this.formData[otpCodeRef] != null) {
        this.formData[otpCodeRef] = null;
      }

      this.$emit('change', this.otpcode);
    },
    disableTemporarily(seconds) {
      this.isTemporarilyDisabled = true;
      setTimeout(() => {
        this.isTemporarilyDisabled = false;
      }, seconds * 1000);
    },
  },
  watch: {
    otpNumber: {
      handler: function(val) {
        if (val && val.length === 4) {
          for (let chara in val) {
            // let otpCodeRef = `otpcode${parseInt(chara) + 1}`;
            // this.$refs[otpCodeRef].focus();
            // this.formData[otpCodeRef] = val[chara];
            this.handleInput((parseInt(chara) + 1), val[chara]);
          }
        }
      },
      deep: true
    },
    resetFields: function (newVal) {
      if(newVal == true) {
        this.resetInputFields();
      }
    },
    focusFirstInput: function (newVal) {
      if(newVal == true) {
        this.resetInputFields();
        this.setFocusFirstInput();
      }
    }
  },
}
</script>
<style lang="scss">
.otp-container {
  display: inline-block;
//   border-radius: var(--base-radius);
  margin: 10px 0px;
  .el-input {
    display: inline-block;
    width: auto;
    margin-right: 20px;
    &:focus {
      z-index: 1;
    }

    &.is-disabled {
      .el-input__inner {
        background-color: transparent;
      }
    }

    &__inner {
      border: 1px solid #E3E8ED;
      border-radius: 5px;
    //   border-right: none;
      color: var(--platinum);
      text-align: center;
      font-size: 20px;
      font-weight: 600;
      width: 50px;
      height: 50px;

      &:focus {
        border-color: transparent;
        box-shadow: 0px 0px 0px 1px black;
        position: relative;
        z-index: 1;
      }

      &:disabled {
        background-color: transparent;
        color: var(--shadow-blue);
      }
    }
  }
}
</style>